<script lang="ts">
import { useConfigStore } from '~/store'

useHead({
    titleTemplate: 'Pildom Programmation de travaux - %s',
})

export default {
    name: 'DefaultLayout',
    setup() {
        const configStore = useConfigStore()

        return {
            configStore,
        }
    },
    computed: {
        currentPage() {
            return this.$currentPath(this.$route.matched[0].path).pop()
            // return this.$refs && this.$refs.MenuBreadcrumbs && this.$refs.MenuBreadcrumbs.breadcrumbItems.slice(-1)
        },
        loading() {
            return useConfigStore()?.globalLoading
        },
        maintenance() {
            return useConfigStore()?.maintenance
        },

    },
    mounted() {
        useConfigStore().setGlobalLoading(false)
        this.configStore.currentConfig.homepage_view = 'connexion'
        if (this.$config.public.app_slug) {
            this.$api.setRequest({
                url: `applications/${this.$config.public.app_id}`,
                method: 'GET',
            }).then((res) => {
                this.configStore.currentConfig.app_info = res.data
            })
        }
    },
}
</script>

<template>
    <v-layout>
        <MenuGlobalLoader :loading="loading">
            <template #loader>
                <svg viewBox="25 25 50 50">
                    <circle
                        class="loader-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#636363"
                        stroke-width="2"
                    />
                </svg>
            </template>
            <template #logo />
        </MenuGlobalLoader>

        <MenuMaintenanceMessage>
            <template #logo />
        </MenuMaintenanceMessage>

        <client-only>
            <v-main
                v-if="!maintenance && !loading"
                id="main-content"
                class="bg-custom-light"
            >
                <header>
                    <MenuAppBar :disable-navigation="true" :elevation="0">
                        <template #title>
                            <v-row align="center">
                                <v-col cols="3" md="3" lg="2" class="text-center">
                                    <img
                                        @click="$router.push('/')"
                                        class="w-auto pt-1 logo"
                                        src="@/assets/images/amiante_bleu2_carre.png"
                                        alt="Pildom logo"
                                        height="70"
                                    >
                                </v-col>
                                <v-col v-if="$vuetify.display.mdAndUp" cols="9">
                                    Programmation de travaux
                                </v-col>
                            </v-row>
                        </template>
                        <template #rightMenu>
                            <MenuAppbarUserProfile>
                                <template #loginbuttons>
                                    <v-btn
                                        class="mx-2"
                                        variant="outlined"
                                        @click="configStore.currentConfig.homepage_view = 'connexion'"
                                    >
                                        Connexion
                                    </v-btn>
                                    <v-btn
                                        class="mx-2"
                                        variant="outlined"
                                        @click="configStore.currentConfig.homepage_view = 'register'"
                                    >
                                        Créer un compte
                                    </v-btn>
                                </template>
                            </MenuAppbarUserProfile>
                        </template>
                    </MenuAppBar>
                </header>
                <!-- TO DO : bg-primary lorsque l'utilisateur n'est pas connecté ou en attente d'acceptation  :class="$currentUser()" -->
                <div id="page-content" class="pa-6" :class="$hasAccess($config.public.app_slug) ? 'bg-light' : 'bg-primary'">
                    <div id="page-header" class="mb-4">
                        <Breadcrumbs v-if="$route.path !== '/' || $hasAccess($config.public.app_slug)" />
                        <h1
                            v-if="currentPage"
                            class="text-h4"
                        >
                            {{ $translate(currentPage.title) }}
                        </h1>
                    </div>
                    <!-- <MenuGlobalAlert /> -->
                    <slot />
                    <Toaster />
                </div>
                <MenuFooter color="primary" />
            </v-main>
        </client-only>
    </v-layout>
</template>
